exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-grenar-index-js": () => import("./../../../src/pages/grenar/index.js" /* webpackChunkName: "component---src-pages-grenar-index-js" */),
  "component---src-templates-anmalan-page-js": () => import("./../../../src/templates/anmalan-page.js" /* webpackChunkName: "component---src-templates-anmalan-page-js" */),
  "component---src-templates-blog-page-js": () => import("./../../../src/templates/blog-page.js" /* webpackChunkName: "component---src-templates-blog-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-info-page-js": () => import("./../../../src/templates/info-page.js" /* webpackChunkName: "component---src-templates-info-page-js" */),
  "component---src-templates-tools-js": () => import("./../../../src/templates/tools.js" /* webpackChunkName: "component---src-templates-tools-js" */)
}

